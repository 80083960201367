// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar{
    display: contents;
    width: 6px;
    height: 6px;
}


::-webkit-scrollbar-thumb{
    background: rgba(0,0,0,0.2);
    border: 5px;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.2); 
}

::-webkit-scrollbar-corner {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/scrollbar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;IACV,WAAW;AACf;;;AAGA;IACI,2BAA2B;IAC3B,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;AACjB","sourcesContent":["::-webkit-scrollbar{\n    display: contents;\n    width: 6px;\n    height: 6px;\n}\n\n\n::-webkit-scrollbar-thumb{\n    background: rgba(0,0,0,0.2);\n    border: 5px;\n    border-radius: 25px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background: rgba(0,0,0,0.2); \n}\n\n::-webkit-scrollbar-corner {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
