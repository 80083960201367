// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* p {
    padding: 0;
    margin: 0;
} */

.Interview_row__3uHY9>p {
    padding: 0;
    margin: 0;
}

.Interview_row__3uHY9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ecf0f3;
    color: #4b4f52;
    font-weight: 600;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    padding: 0.25rem 1.25rem;
    border-radius: .25rem;

}

.Interview_idealBtn__1-f9X {
    background-color: rgb(40, 166, 72) !important;
    color: #fff !important;
    font-size: 0.7rem !important;
    font-weight: bold;
    text-transform: none !important;
    padding: 4px 1rem !important;
    min-width: auto !important;
    padding: 2px 20px !important;
    visibility: hidden;
}

.Interview_reportBtn__6ECfl {
    background-color: #3F1665 !important;
    margin-right: 0.75rem !important;
    color: #fff !important;
    font-size: 0.7rem !important;
    font-weight: bold !important;
    text-transform: none !important;
    padding: 2px 20px !important;
}


.Interview_case__RVcZW {
    min-width: 150px;
}


/* .case {
    width: 25%;
}

.date {
    width: 40%;
}

.score {
    width: 20%;
}

.btnGroup {
    width: 30%;
} */`, "",{"version":3,"sources":["webpack://./src/styles/Interview.module.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;IACxB,qBAAqB;;AAEzB;;AAEA;IACI,6CAA6C;IAC7C,sBAAsB;IACtB,4BAA4B;IAC5B,iBAAiB;IACjB,+BAA+B;IAC/B,4BAA4B;IAC5B,0BAA0B;IAC1B,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;IAC5B,+BAA+B;IAC/B,4BAA4B;AAChC;;;AAGA;IACI,gBAAgB;AACpB;;;AAGA;;;;;;;;;;;;;;GAcG","sourcesContent":["/* p {\n    padding: 0;\n    margin: 0;\n} */\n\n.row>p {\n    padding: 0;\n    margin: 0;\n}\n\n.row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #ecf0f3;\n    color: #4b4f52;\n    font-weight: 600;\n    font-size: 0.75rem;\n    margin-top: 0.25rem;\n    padding: 0.25rem 1.25rem;\n    border-radius: .25rem;\n\n}\n\n.idealBtn {\n    background-color: rgb(40, 166, 72) !important;\n    color: #fff !important;\n    font-size: 0.7rem !important;\n    font-weight: bold;\n    text-transform: none !important;\n    padding: 4px 1rem !important;\n    min-width: auto !important;\n    padding: 2px 20px !important;\n    visibility: hidden;\n}\n\n.reportBtn {\n    background-color: #3F1665 !important;\n    margin-right: 0.75rem !important;\n    color: #fff !important;\n    font-size: 0.7rem !important;\n    font-weight: bold !important;\n    text-transform: none !important;\n    padding: 2px 20px !important;\n}\n\n\n.case {\n    min-width: 150px;\n}\n\n\n/* .case {\n    width: 25%;\n}\n\n.date {\n    width: 40%;\n}\n\n.score {\n    width: 20%;\n}\n\n.btnGroup {\n    width: 30%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Interview_row__3uHY9`,
	"idealBtn": `Interview_idealBtn__1-f9X`,
	"reportBtn": `Interview_reportBtn__6ECfl`,
	"case": `Interview_case__RVcZW`
};
export default ___CSS_LOADER_EXPORT___;
