import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import CaseCard from './CaseCard'
import '../../styles/CaseCard.css'
import axios from '../../axios'
import Loader from '../../utils/Loader'

export default function CaseSelector(props) {
  const [isLoading, setisLoading] = useState(true)

  const [cases, setCases] = useState({})

  useEffect(() => {
    axios
      .get('/convo-list')
      .then((res) => {
        setCases(res.data)
        setisLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  return (
    <div>
      <Container maxWidth='lg' style={{ paddingBottom: '200px' }}>
        <Grid container spacing={6}>
          {isLoading ? (
            <Loader />
          ) : (
           cases.map((value) => (
              <Grid
                item
                key={value}
                xs={12}
                sm={6}
                md={4}
                style={{ padding: '24px 10px' }}
              >
                <CaseCard
                  heading={value.name}
                  difficulty={value.attributes.level}
                  about={value.attributes.type}
                  icon='steel.png'
                  id={value.productId}
                  problem={value.description}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </div>
  )
}
