// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customModalChats {
  width: 90vw;
  margin: 20px;
}

.container-team {
  flex: 4 1;
  padding: 5vh 4vw 2vh 4vw;
  background: #eee;
  min-height: 93vh;
}

.heading-team {
  display: flex;
}

.heading-team div {
  margin: 0px;
}

.row-team {
  background: #fff;
  border: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0;
}

.row-column-team {
  padding: 15px 1vw 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/all-chats/ChatShow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAO;EACP,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".customModalChats {\n  width: 90vw;\n  margin: 20px;\n}\n\n.container-team {\n  flex: 4;\n  padding: 5vh 4vw 2vh 4vw;\n  background: #eee;\n  min-height: 93vh;\n}\n\n.heading-team {\n  display: flex;\n}\n\n.heading-team div {\n  margin: 0px;\n}\n\n.row-team {\n  background: #fff;\n  border: 1px solid #ddd;\n  align-items: center;\n  justify-content: center;\n  margin: 15px 0 0;\n}\n\n.row-column-team {\n  padding: 15px 1vw 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
