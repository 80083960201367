import { Paper } from '@material-ui/core'
import styles from '../styles/ForgotPassword.module.css'
import { PrimaryButton, PrimaryInput } from './MaterialComponents'
import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import axios from '../axios';
import { useHistory } from 'react-router-dom';

function SetNewPassword() {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState('')
    let history = useHistory()

    const handleForgot = (e) => {
        e.preventDefault()
        const data = {
            password:password,
        }

        if((password === "") || password !== confirmPassword){
            alert("Passwords do not match. Please enter same passwords.")
            setErrors('')
            return
        }


        const url =
            '/auth/set-new-password'
        axios
            .post(url, data)
            .then((res) => {
                alert('New Password Set Successful')
                history.push('/signin')
            })
            .catch((err) => {
                alert(err?.response?.data?.message)
            })

        e.preventDefault()
    }

    return (
        <div className={styles.forgotMain}>
            <Paper elevation={10} className={styles.paper}>
                <div align='center'>
                    <h1
                        style={{
                            marginBottom: '0',
                            fontSize: '2rem',
                        }}
                    >
                        Set Your New Password
                    </h1>
                </div>
                <div style={{marginLeft: '2rem'}}>
                    <p style={{color: '#555', fontWeight: '600', marginTop:"2rem"}}>Enter New Password</p>
                    <PrimaryInput
                        error
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <img src='./user.png' style={{height: '2rem'}} alt=''></img>
                                </InputAdornment>
                            ),
                        }}
                        placeholder='Enter your new password here'
                        type='password'
                        value={password}
                        helperText={errors.email}
                        onChange={(e) => setPassword(e.target.value)}
                    ></PrimaryInput>
                    <p style={{color: '#555', fontWeight: '600', marginTop:"2rem"}}>Enter Password Again</p>
                    <PrimaryInput
                        error
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <img src='./user.png' style={{height: '2rem'}} alt=''></img>
                                </InputAdornment>
                            ),
                        }}
                        placeholder='Confirm new password'
                        type='password'
                        value={confirmPassword}
                        helperText={errors.email}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    ></PrimaryInput>
                    <div
                        style={{
                            marginTop: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '2rem',
                        }}
                        align='center'
                    >
                        <PrimaryButton style={{width: '100%'}} onClick={handleForgot}>
                            SET NEW PASSWORD
                        </PrimaryButton>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default SetNewPassword
