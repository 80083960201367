import React from 'react'
import Navbar from '../components/Navbar'
import History from '../components/History'
import '../App.css'
import useFetchUser from "../custom-hooks/useFetchUser";


const HistoryPage = () => {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
      <History />
    </div>
  )
}

export default HistoryPage
