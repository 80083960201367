import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "../axios";

const useFetchUser = () => {
    let history = useHistory()
    const [data, setData] = useState({});
    useEffect(() => {
        axios
            .get('/user')
            .then((res) => {
                if (res.status === 200) {
                    let data = {}
                    data.name = res.data.firstName + ' ' + res.data.lastName
                    data.email = res.data.email
                    data.institution = res.data.institution
                    setData(data)
                } else {
                    history.push('/signin')
                }
            })
            .catch((err) => {
                history.push('/signin')
                console.log(err)
            })
    }, [history])
    return data;
}

export default useFetchUser;