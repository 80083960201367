import { Paper, Link } from '@material-ui/core'
import styles from '../styles/ForgotPassword.module.css'
import { PrimaryButton, PrimaryInput } from './MaterialComponents'
import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import SignInPage from '../views/SignInPage'
import { useHistory } from 'react-router-dom'
import { validateEmail } from '../utils/validation/validateUtils'
import axios from '../axios';

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [errors, setErrors] = useState('')
  let history = useHistory()

  const handleForgot = (e) => {
    e.preventDefault()
    setErrors('')
    const data = {
      email,
    }

    const errorResult = validateEmail(data.email)

    if (!errorResult.isValid) {
      setErrors(errorResult.errors)
      console.log(errors)
      return
    }
    const url =
      '/auth/forgot-password'
    axios
      .post(url, data)
      .then((res) => {
        setValidEmail(true)
        history.push('/signin')
        alert('Email Sent Successfully. Please check your email for password reset link')
      })
      .catch((err) => {
        setValidEmail(false)
          alert(err?.response?.data?.message)
      })

    e.preventDefault()
  }

  return validEmail ? (
    <SignInPage />
  ) : (
    <div className={styles.forgotMain}>
      <img
        className={styles.sideImage}
        src='./20943587.jpg'
        height='450px'
        alt=''
      ></img>
      <Paper elevation={10} className={styles.paper}>
        <div align='center'>
          <h1
            style={{
              marginBottom: '0',
              fontSize: '2.5rem',
            }}
          >
            Forgot{' '}
          </h1>
          <h1 style={{ marginTop: '0.5rem', fontSize: '2.5rem' }}>
            Password?{' '}
          </h1>
        </div>
        <div style={{ marginLeft: '2rem' }}>
          <p style={{ color: '#555', fontWeight: '600' }}>Email Address</p>
          <PrimaryInput
            error
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src='./user.png' style={{ height: '2rem' }} alt=''></img>
                </InputAdornment>
              ),
            }}
            placeholder='Enter your email address'
            type='email'
            value={email}
            helperText={errors.email}
            onChange={(e) => setEmail(e.target.value)}
          ></PrimaryInput>
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '2rem',
            }}
            align='center'
          >
            <Link href='/forgotpassword'>
              <PrimaryButton style={{ width: '100%' }} onClick={handleForgot}>
                RESET PASSWORD
              </PrimaryButton>
            </Link>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default ForgotPassword
