// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-div {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}

.modal-text {
  padding: 20px;
  font-size: large;
  font-weight: 400;
}

.modal-button {
  color: white !important;
  background: #0C95BA !important;
  margin-right: 3px !important;
}

.cancel-modal-button {
  color: white !important;
  background: #0e7794 !important;
  margin-right: 3px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,4BAA4B;AAC9B","sourcesContent":[".button-div {\n  display: flex;\n  flex-direction: row-reverse;\n  padding: 0px;\n}\n\n.modal-text {\n  padding: 20px;\n  font-size: large;\n  font-weight: 400;\n}\n\n.modal-button {\n  color: white !important;\n  background: #0C95BA !important;\n  margin-right: 3px !important;\n}\n\n.cancel-modal-button {\n  color: white !important;\n  background: #0e7794 !important;\n  margin-right: 3px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
