import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import axios from '../../../axios'
import { Chart as ChartJS, LineElement, LinearScale, PointElement, CategoryScale } from "chart.js";
ChartJS.register(LineElement, LinearScale, PointElement, CategoryScale);

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
        },
      },
    ],
  },
}

export default function LineChart(dataType) {
  const [dataANL, setdataANL] = useState({labels: [],datasets: [{label: "",data: []}]})
  const [dataSYN, setdataSYN] = useState({labels: [],datasets: [{label: "",data: []}]})
  const [dataSTR, setdataSTR] = useState({labels: [],datasets: [{label: "",data: []}]})
  const [dataJDG, setdataJDG] = useState({labels: [],datasets: [{label: "",data: []}]})
  useEffect(() => {
    axios
      .get('/user-scores')
      .then((res) => {
          if(res.data.message != "You have not attempted any case yet") {
              const lineChartData = res.data.filter((object) => {
                  if (object.score) return object
                  else return null
              })
              lineChartData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
              const ReqANL = {
                  labels: lineChartData.map((object, index) => (index)),

                  datasets: [
                      {
                          label: '# of Votes',
                          data: lineChartData.map(object => (object.score["anl"])),
                          fill: false,
                          backgroundColor: 'rgb(255, 99, 132)',
                          borderColor: 'rgba(255, 99, 132, 0.2)',
                          tension: 0.5,
                      },
                  ],
              }
              setdataANL(ReqANL);
              const ReqJDG = {
                  labels: lineChartData.map((object, index) => (index)),

                  datasets: [
                      {
                          label: '# of Votes',
                          data: lineChartData.map(object => (object.score["jdg"])),
                          fill: false,
                          backgroundColor: 'rgb(255, 99, 132)',
                          borderColor: 'rgba(255, 99, 132, 0.2)',
                          tension: 0.5,
                      },
                  ],
              }
              setdataJDG(ReqJDG);
              const ReqSTR = {
                  labels: lineChartData.map((object, index) => (index)),

                  datasets: [
                      {
                          label: '# of Votes',
                          data: lineChartData.map(object => (object.score["str"])),
                          fill: false,
                          backgroundColor: 'rgb(255, 99, 132)',
                          borderColor: 'rgba(255, 99, 132, 0.2)',
                          tension: 0.5,
                      },
                  ],
              }
              setdataSTR(ReqSTR);
              const ReqSYN = {
                  labels: lineChartData.map((object, index) => (index)),

                  datasets: [
                      {
                          label: '# of Votes',
                          data: lineChartData.map(object => (object.score["syn"])),
                          fill: false,
                          backgroundColor: 'rgb(255, 99, 132)',
                          borderColor: 'rgba(255, 99, 132, 0.2)',
                          tension: 0.5,
                      },
                  ],
              }
              setdataSYN(ReqSYN);
          }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  if(dataType.data==="ANL")
  return (
    <div>
      <Line data={dataANL} options={options} height={500} />
    </div>
  )
  else if(dataType.data==="JDG")
  return (
    <div>
      <Line data={dataJDG} options={options} height={500} />
    </div>
  )
  else if(dataType.data==="STR")
  return (
    <div>
      <Line data={dataSTR} options={options} height={500} />
    </div>
  )
  else if(dataType.data==="SYN")
  return (
    <div>
      <Line data={dataSYN} options={options} height={500} />
    </div>
  )
}

