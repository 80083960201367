import * as React from 'react'
import { useState } from 'react'
import { Container, Link } from '@material-ui/core'
import {
  NavAppBar,
  NavToolBar,
  SecondaryButton,
  PrimaryButton,
} from './MaterialComponents'
import MenuIcon from '@material-ui/icons/Menu'
import styles from '../styles/Navbar2.module.css'
import { useWindowDimensions } from '../utils/windowUtils'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const Navbar2 = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { width } = useWindowDimensions()

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <NavAppBar style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
      <Container maxWidth='lg'>
        <NavToolBar>
          <Link
            href='/'
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              textDecoration: 'none',
            }}
          >
            <span ><img style={{height:"8vh", width:"10vw"}} src="/casey-logo.png" /></span>
          </Link>

          {width >= 992 ? (
            <div
              className={styles.navLink}
              style={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link href='/signIn'>
                <SecondaryButton>Log In</SecondaryButton>
              </Link>
              <Link href='/signUp'>
                <PrimaryButton>Sign Up</PrimaryButton>
              </Link>
            </div>
          ) : (
            <div>
              <MenuIcon
                htmlColor='black'
                fontSize='large'
                id='basic-button'
                aria-controls='basic-menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              ></MenuIcon>
              <Menu
                className={styles.Menu}
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link href='/signIn'>
                    <SecondaryButton>Log In</SecondaryButton>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href='/signUp'>
                    <PrimaryButton>Sign Up</PrimaryButton>
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          )}
        </NavToolBar>
      </Container>
    </NavAppBar>
  )
}

export default Navbar2
