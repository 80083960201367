import React from 'react'
import Navbar from '../components/Navbar'
import CaseSelector from '../components/cases2/CaseSelector'
import Cases from '../components/cases2/Cases'
import '../App.css'
import useFetchUser from "../custom-hooks/useFetchUser";

const CasesPage = ({ cards }) => {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
      <Cases />
      <CaseSelector cards={cards} />
    </div>
  )
}

export default CasesPage
