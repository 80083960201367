import React, {useEffect, useState} from 'react'
import {Typography, Grid, Container} from '@material-ui/core'
import '../styles/Cases.css'
import {useWindowDimensions} from '../utils/windowUtils'
import {useParams} from 'react-router-dom';
import {Paper} from '@material-ui/core'
import styles from '../styles/UserDetails.module.css'
import axios from "../axios"
import {Button} from '@material-ui/core'
import {useHistory} from 'react-router'

export default function LandingPage() {
    let history = useHistory()

    const {userId, caseNo} = useParams();
    const [heading, setHeading] = useState('')
    const [email, setEmail] = useState('')
    const [prob, setProb] = useState('')
    const [go, setGo] = useState(false)
    const [load, setLoad] = useState(true)

    const {width} = useWindowDimensions()
    const headingStyle = {
        fontSize: width > 768 ? '5em' : '3.5rem',
        fontWeight: 'bold',
        color: '#fff',
        marginTop: '4rem',
    }

    const startSession = (e) => {
        history.push({
            pathname: '/chatbox',
            state: {id: caseNo, heading: heading, prob: prob, email: email, resume: false}
        })
        e.preventDefault()
    }

    useEffect(() => {
        axios.get('/auth/taketest/' + userId + '/' + caseNo).then((res1) => {
            axios
                .post('/check-cases-external', {caseNo: caseNo})
                .then((res) => {
                    setProb(res.data.prob)
                    setHeading(res.data.heading)
                    setEmail(res.data.email)
                    setGo(true)
                    setLoad(false)

                })
                .catch((err) => {
                    setLoad(false)
                    console.log(err)
                })
        }).catch((err) => {
            setLoad(false)
            console.log(err)
        })
    }, [userId, caseNo])


    return (
        <div id="background"
             style={{background: "rgb(54, 55, 64)", minHeight: "100vh", width: "100vw", height: "110%", margin: "0"}}>
            <Container maxWidth='lg'>
                <Grid container spacing={4} className='hero'>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        align={width > 768 ? 'left' : 'center'}
                        className='heroTextSection'
                    >
                        <Typography variant='h1' style={headingStyle}>
                            Welcome to Casey
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align='center'>
                        <Container>
                            <img
                                src='/goal.png'
                                alt=''
                                srcset=''
                                style={{width: 300, height: 300}}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Container>
            <div>
                <Paper elevation={10} className={styles.paper}>
                    <h2>Guidelines</h2>
                    <ul>
                        <br/>
                        <li>
                            To ensure you get maximum value from this practice case, you need to treat it the same way
                            you would a real-live case interview.
                        </li>
                        <br/>
                        <li>
                            Please make sure that you have some paper and a pen or pencil handy. This will allow you to
                            take notes, draw out your proposed structures, and lay out your calculations throughout the
                            case. We recommend you do this on a computer or laptop rather than a mobile device.
                        </li>
                        <br/>
                        <li>
                            Critical information will be relayed in small doses throughout the case, just as in a real
                            case. It is important that you keep track of important numbers, hypotheses or interim
                            conclusions, and be able to quickly find it if needed later.
                        </li>
                        <br/>
                        <li>
                            This will minimize having to ask the interviewer the same question multiple times, or in
                            this practice case, from having to navigate back and forth through the case to find the
                            required information. There will be calculations in this case, just as in a real case.
                        </li>
                        <br/>
                        <li>
                            You can use a spreadsheet or calculator if you wish, although most live interviews will
                            require you to perform some manual calculations, so it would be helpful to get some
                            practice.
                        </li>
                        <br/>
                        <li>
                            We recommend you first write out the steps of your calculation, then fill in the actual
                            numbers, to make it easy to retrace your steps if you make a mistake. After each question,
                            we provide feedback on your choice of answer, as well as the reasoning behind the correct
                            answer. Do take time to reflect on where you went right (or wrong), and consciously apply
                            the insights to future cases.
                        </li>
                        <br/>
                        <li>
                            Good luck with your practice!
                        </li>
                    </ul>
                </Paper>

                <Paper elevation={10} className={styles.paper}>

                    <p> Please refer here for our <a target="_blank"
                                                     rel={"noreferrer"}
                                                     href="https://drive.google.com/file/d/1DUrnaCMgEu06Jaf_OxlN9kWrCp2oCzVv/view?usp=sharing">Privacy
                        Policy</a></p>
                    {load ? null : <Button
                        className='card-button'
                        disabled={!go}
                        style={{
                            backgroundColor: '#006FFF',
                            color: 'whitesmoke',
                            marginTop: '10px',
                            textTransform: 'none',
                            padding: '10px 24px !important',
                        }}
                        onClick={startSession}
                    >
                        Start Now
                    </Button>}
                </Paper>
            </div>
        </div>
    )
}
                  