// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Interviews_paper__UyJD2 {

    width: 70vh;
    margin: 1rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem !important;
    padding-left: 3rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.Interviews_background__e7w3O {
    background: linear-gradient(135deg, #131047, #8515b9);
}

/* p {
    padding: 0;
    margin: 0;
} */

.Interviews_tableHead__wQx\\+H>p {
    padding: 0;
    margin: 0;
}

.Interviews_tableHead__wQx\\+H {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2074ec;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 1rem;
    padding: 0.25rem 2.5rem;
    border-radius: .25rem;
}

@media (max-width: 600px) {
    .Interviews_paper__UyJD2 {
        width: 50vh !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Interviews.module.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,qDAAqD;AACzD;;AAEA;;;GAGG;;AAEH;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".paper {\n\n    width: 70vh;\n    margin: 1rem auto;\n    padding: 2rem;\n    background: #fff;\n    border-radius: 1rem !important;\n    padding-left: 3rem;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n.background {\n    background: linear-gradient(135deg, #131047, #8515b9);\n}\n\n/* p {\n    padding: 0;\n    margin: 0;\n} */\n\n.tableHead>p {\n    padding: 0;\n    margin: 0;\n}\n\n.tableHead {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #2074ec;\n    color: #fff;\n    font-size: 0.9rem;\n    font-weight: bold;\n    margin-top: 1rem;\n    padding: 0.25rem 2.5rem;\n    border-radius: .25rem;\n}\n\n@media (max-width: 600px) {\n    .paper {\n        width: 50vh !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `Interviews_paper__UyJD2`,
	"background": `Interviews_background__e7w3O`,
	"tableHead": `Interviews_tableHead__wQx+H`
};
export default ___CSS_LOADER_EXPORT___;
