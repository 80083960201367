import React from 'react'
import {
  Container,
  Grid,
  Box,
  InputAdornment,
  Input,
  InputLabel,
  Paper,
  Modal,
  Typography,
  Divider,
} from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search'
import { useState, useEffect } from 'react'
import CaseCard from './CaseCard'
import '../../styles/CaseCard.css'
import { PrimaryInput } from '../MaterialComponents'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'

import ArchiveIcon from '@mui/icons-material/Archive'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'
import { StylesContext } from '@material-ui/styles'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import axios from '../../axios'
import Loader from '../../utils/Loader'
import { width } from '@mui/system'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#000',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
}))

const style = {
  position: 'absolute',
  top: '80%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

// const filters = {
//   display: 'flex',
// }

export default function CaseSelector(props) {
  const [searchInput, setSearchInput] = useState('')
  // const [cases, setCases] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [cards, setCards] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  // const [I, setI] = useState(false)
  // const [P, setP] = useState(false)
  // const [G, setG] = useState(false)
  // const [E, setE] = useState(false)
  const open = Boolean(anchorEl)
  const [items, setItems] = useState([])
  const [arrLength, setArrLength] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOpen = () => setIsModalOpen(true)
  const handleClose = () => setIsModalOpen(false)
  // const [isModalOpen,setIsModalOpen] = useState(false)

  useEffect(() => {
    axios
      .get('/all-products')
      .then((res) => {
        setCards(res.data)
        setisLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  const handleCheckout = () => {
    console.log('checkout')
    const getData = items
    axios
      .post('/create-order', getData)
      .then((res) => {
        window.open(
          res.data.url,
          '_blank',
          'location=yes,height=570,width=520,scrollbars=yes,status=yes'
        )
        window.location.href = '/cases'
      })
      .catch((err) => {
        console.log(err.response)
      })

    // getData = { list: array }
  }

  const handleCard = (e, id) => {
    // console.log(id)

    e.preventDefault()
    if (e.target.innerText === 'Add To Cart') {
      setTotalPrice(totalPrice + Number(cards[id].price))
      setItems((prevState) => [...prevState, cards[id]])
      setArrLength((arrLength) => arrLength + 1)
      e.target.innerText = 'Remove from Cart'
    } else if (e.target.innerText === 'Remove from Cart') {
      const index = items.indexOf(cards[id])
      if (index > -1) {
        setTotalPrice(totalPrice - Number(cards[id].price))
        items.splice(index, 1)
        setArrLength((arrLength) => arrLength - 1)
      }
      e.target.innerText = 'Add To Cart'
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // const sortByDifficulty = (val) => {
  //   setCards(
  //     props.cards.filter((card) =>
  //       card.difficulty.toLowerCase().includes(val.toLowerCase())
  //     )
  //   )
  // }

  // const sortByAbout = (val) => {
  //   setCards(
  //     props.cards.filter((card) =>
  //       card.about.toLowerCase().includes(val.toLowerCase())
  //     )
  //   )
  // }

  // const handleClose = (e) => {
  //   if (!e) {
  //     setCards(props.cards)
  //     return
  //   }
  //   const val = e.target.innerText
  //   if (val[0] === 'I') {
  //     setI(true)
  //     sortByDifficulty(val)
  //   } else if (val[0] === 'E') {
  //     setE(true)
  //     sortByDifficulty(val)
  //   } else if (val[0] === 'P') {
  //     setP(true)
  //     sortByAbout(val)
  //   } else if (val[0] === 'G') {
  //     setG(true)
  //     sortByAbout(val)
  //   } else {
  //     setCards(props.cards)
  //   }

  //   setAnchorEl(null)
  // }

  const handleSearchInput = (val) => {
    console.log(val)
    setCards(
      props.cards.filter((card) =>
        card.heading.toLowerCase().includes(val.toLowerCase())
      )
    )
    console.log(cards)
  }

  const searchStyle = {
    marginLeft: '1rem',
    width: '75%',
    backgroundColor: '#e0e0e0',
    borderRadius: '10px',
    alignSelf: 'center',
  }

  return (
    <div>
      <Container maxWidth='lg' style={{ paddingBottom: '200px' }}>
        <Box mt={8} mb={8}>
          <PrimaryInput
            style={searchStyle}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant='outlined'
            id='input-with-icon-adornment'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
            placeholder='Search'
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value)
              handleSearchInput(e.target.value)
            }}
          />
          <Box
            ml={4}
            mt={1}
            sx={{ display: 'inline-block', verticalAlign: 'center' }}
          >
            <Button
              id='demo-customized-button'
              aria-controls='demo-customized-menu'
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              variant='contained'
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              style={{
                backgroundColor: '#e0e0e0',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Filters
            </Button>

            {/* <StyledMenu
              id='demo-customized-menu'
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={(e) => handleClose(e)} disableRipple>
                Intermediate
              </MenuItem>
              <MenuItem onClick={(e) => handleClose(e)} disableRipple>
                Profitability
              </MenuItem>
              <MenuItem onClick={(e) => handleClose(e)} disableRipple>
                Growth Strategy
              </MenuItem>
              <MenuItem onClick={(e) => handleClose(e)} disableRipple>
                Easy
              </MenuItem>
            </StyledMenu> */}
          </Box>
          {/* <Box mt={4} ml={4}>
            <Stack direction='row' spacing={1}>
              {I && (
                <Chip
                  label='Intermediate'
                  onDelete={() => {
                    {
                      setI(false)
                      handleClose()
                    }
                  }}
                  style={{ backgroundColor: '#fff' }}
                />
              )}

              {P && (
                <Chip
                  label='Profitability'
                  onDelete={() => {
                    setP(false)
                    handleClose()
                  }}
                  style={{ backgroundColor: '#fff' }}
                />
              )}

              {G && (
                <Chip
                  label='Growth Strategy'
                  onDelete={() => {
                    setG(false)
                    handleClose()
                  }}
                  style={{ backgroundColor: '#fff' }}
                />
              )}

              {E && (
                <Chip
                  label='Easy'
                  onDelete={() => {
                    setE(false)
                    handleClose()
                  }}
                  style={{ backgroundColor: '#fff' }}
                />
              )}
            </Stack>
          </Box> */}
        </Box>

        <Grid container spacing={6}>
          {isModalOpen && (
            <Modal
              open={isModalOpen}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Name</p>
                  <p>Price</p>
                </div>
                {/* <Divider /> */}
                {items.map((item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>
                        <strong>{item.name} </strong>
                      </p>
                      <p>
                        {' '}
                        <strong>{item.price} </strong>
                      </p>
                    </div>
                  )
                })}

                <Divider />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h3>
                    Price: <strong>{totalPrice}$</strong>
                  </h3>
                  <Button
                    // style={{ padding: '0.5rem 1rem' }}
                    variant='contained'
                    size='small'
                    onClick={handleCheckout}
                  >
                    Check Out{' '}
                  </Button>
                </div>
              </Box>
            </Modal>
          )}
          {cards.map((card, index) => (
            <Grid
              item
              key={card}
              xs={12}
              sm={6}
              md={4}
              style={{ padding: '24px 10px' }}
            >
              <CaseCard
                heading={card.name}
                difficulty={card.attributes.level}
                about={card.attributes.type}
                icon={card.price}
                key={card._id}
                id={index}
                clickHandler={handleCard}
              />
            </Grid>
          ))}
        </Grid>
        {arrLength > 0 && (
          <footer
            onClick={handleOpen}
            style={{
              bottom: 1,
              position: 'fixed',
              width: '80%',
              backgroundColor: '#ccc',
              height: '50px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 10px',
            }}
          >
            <h3>
              Price: <strong>{totalPrice}$</strong>
            </h3>
            <Button
              // style={{ padding: '0.5rem 1rem' }}
              variant='contained'
              size='small'
              onClick={handleCheckout}
            >
              Check Out{' '}
            </Button>
          </footer>
        )}
      </Container>
    </div>
  )
}
