// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetForm_paper__9hLfb {
    width: 70vh;
    margin: 1rem auto;
    padding: 4rem 2rem;
    background: #fff;
    border-radius: 1rem !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

@media (max-width: 600px) {
    .ResetForm_paper__9hLfb {
        width: 85vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/ResetForm.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;IAC9B,2CAA2C;;AAE/C;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".paper {\n    width: 70vh;\n    margin: 1rem auto;\n    padding: 4rem 2rem;\n    background: #fff;\n    border-radius: 1rem !important;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n\n}\n\n@media (max-width: 600px) {\n    .paper {\n        width: 85vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `ResetForm_paper__9hLfb`
};
export default ___CSS_LOADER_EXPORT___;
