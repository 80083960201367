import * as React from 'react'
import {useState} from 'react'
import { Container, Link } from '@material-ui/core'
import { NavAppBar, NavToolBar, NavDrawer } from './MaterialComponents'
import MenuIcon from '@material-ui/icons/Menu'
import styles from '../styles/Navbar.module.css'
import { useWindowDimensions } from '../utils/windowUtils'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useHistory } from 'react-router-dom'
import axios from '../axios'

const Navbar = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { width } = useWindowDimensions()

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open)
  }

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }


  const handleClose = () => {
    setAnchorEl(null)
  }

  const redirect = (route)=>{
    history.push(route)
    setAnchorEl(null)
  }

  let history = useHistory()

  const handleLogOut = () => {
    if (localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken')
    }
    if (localStorage.getItem('refreshToken')) {
      localStorage.removeItem('refreshToken')
    }
    axios.post('/auth/logout', {}).then(()=>{
      history.push('/signIn')
    });
  }

  return (
      <NavAppBar>
        <Container maxWidth='lg'>
          <NavToolBar>
            <Link
                href='/'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                  textDecoration: 'none',
                }}
            >
              <span ><img alt={""} style={{height:"8vh", width:"10vw"}} src="casey-logo.png" /></span>
            </Link>

            {width >= 992 ? (
                <div
                    className={styles.navLink}
                    style={{
                      display: 'flex',

                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                >
                  <Link href='/dashboard'>Dashboard</Link>
                  <Link href='/cases'>Cases</Link>
                  <Link href='/history'>History</Link>
                  <div style={{alignSelf: 'center', padding: "0 2rem"}}>
                    <img
                        src='./user (1).png'
                        alt='user image'
                        style={{height: '2rem', display: 'block', marginRight: '0', width: '2rem', cursor: "pointer"}}
                        id='basic-button'
                        aria-controls='basic-menu'
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    ></img>
                  </div>
                  <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                  >
                    <MenuItem onClick={handleClose}>
                      <img
                          style={{
                            width: '2rem',
                            height: '2rem',
                            display: 'block',
                            paddingTop: '0.5rem',
                            marginRight: '1.5rem',
                            paddingLeft: '1rem',
                          }}
                          src='./user (1).png'
                          alt=''
                      ></img>
                      <div style={{paddingRight: '2rem'}}>
                        <h4 style={{marginBottom: '0'}}>{props?.userData?.name}</h4>

                        <small
                            style={{
                              color: '#9e8f8e',
                              margin: '0',
                              fontSize: '0.75rem',
                            }}
                        >
                          {props?.userData?.institution}
                        </small>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      redirect('/profile')
                    }}>
                      <img
                          style={{
                            height: '2rem',
                            display: 'block',
                            paddingTop: '0.5rem',
                            marginRight: '1.5rem',
                            paddingLeft: '1rem',
                          }}
                          src='./gear.png'
                          alt=''
                      ></img>
                      <div style={{paddingRight: '2rem'}}>
                        <h4 style={{marginBottom: '0', marginTop: '0.5rem'}}>
                          Profile Settings
                        </h4>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={handleLogOut}>
                      <img
                          style={{
                            height: '2rem',
                            display: 'block',
                            paddingTop: '0.5rem',
                            marginRight: '1.5rem',
                            paddingLeft: '1rem',
                          }}
                          src='./log-out.png'
                          alt=''
                      ></img>
                      <div style={{paddingRight: '2rem'}}>
                        <h4 style={{marginBottom: '0', marginTop: '0.5rem'}}>
                          Log Out
                        </h4>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
            ) : (
                <div>
                  <div style={{marginRight: '1.2rem', display: 'inline-block'}}>
                    <Link
                        href=''
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                    >
                      <img
                          src='./user (1).png'
                          alt=''
                          style={{
                            width:'2rem',
                            height: '2rem',
                            display: 'block',
                            marginRight: '0',
                          }}
                          id='basic-button'
                          aria-controls='basic-menu'
                          aria-haspopup='true'
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                      ></img>
                    </Link>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                    >
                      <MenuItem onClick={handleClose}>
                        <img
                            style={{
                              width:'2rem',
                              height: '2rem',
                              display: 'block',
                              paddingTop: '0.5rem',
                              marginRight: '1.5rem',
                              paddingLeft: '1rem',
                            }}
                            src='./user (1).png'
                            alt=''
                        ></img>
                        <div style={{ paddingRight: '2rem' }}>
                          <h4 style={{ marginBottom: '0' }}>{props?.userData?.name}</h4>

                          <small
                              style={{
                                color: '#9e8f8e',
                                margin: '0',
                                fontSize: '0.75rem',
                              }}
                          >
                            {props?.userData?.institution}
                          </small>
                        </div>
                      </MenuItem>
                      <MenuItem onClick={()=>{redirect('/profile')}}>
                        <img
                            style={{
                              height: '2rem',
                              display: 'block',
                              paddingTop: '0.5rem',
                              marginRight: '1.5rem',
                              paddingLeft: '1rem',
                            }}
                            src='./gear.png'
                            alt=''
                        ></img>
                        <div style={{ paddingRight: '2rem' }}>
                          <h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
                            Profile Settings
                          </h4>
                        </div>
                      </MenuItem>
                      <MenuItem onClick={handleLogOut}>
                        <img
                            style={{
                              height: '2rem',
                              display: 'block',
                              paddingTop: '0.5rem',
                              marginRight: '1.5rem',
                              paddingLeft: '1rem',
                            }}
                            src='./log-out.png'
                            alt=''
                        ></img>
                        <div style={{ paddingRight: '2rem' }}>
                          <h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
                            Log Out
                          </h4>
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                  <MenuIcon fontSize='large' onClick={() => toggleDrawer(true)} />
                  <NavDrawer
                      anchor={'right'}
                      open={isDrawerOpen}
                      onClose={() => toggleDrawer(false)}
                  >
                    <div role='presentation' onClick={() => toggleDrawer(false)}>
                      <div
                          className={styles.navLink}
                          style={{
                            display: 'block',
                          }}
                      >
                        <Link href=''>Dashboard</Link>
                        <Link href=''>Cases</Link>
                        <Link href=''>History</Link>
                      </div>
                    </div>
                  </NavDrawer>
                </div>
            )}
          </NavToolBar>
        </Container>
      </NavAppBar>
  )
}

export default Navbar
