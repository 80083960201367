import React, { useState, useEffect } from "react";
import styles from "../../styles/CardBody.module.css";
import axios from "../../axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkillsCharts from "./SkillsCharts";
import { NoBackpackSharp } from "@mui/icons-material";
import { useHistory } from "react-router-dom";


export default function CardBody() {
  const [score, setScore] = useState(0);
  const [highestProperty, sethighestProperty] = useState("Analysis");
  const [lowestProperty, setlowestProperty] = useState("Analysis");
  const [caseSolved, setCaseSolved] = useState(0);
  const [lowestMetrics, setlowestMetrics] = useState([0, 0]);
  const [highestMetrics, sethighestMetrics] = useState([0, 0]);
  let history = useHistory();
  const [name, setName] = useState("");
  useEffect(() => {
    axios
      .get("/user")
      .then((res) => {
        setName(res.data.firstName + " " + res.data.lastName);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/user-scores")
      .then((res) => {
          if (res.data.message != "You have not attempted any case yet") {
              const lineChartData = res.data.filter((object) => {
                  if (object.score) return object;
              });
              lineChartData.sort(
                  (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
              );
              setCaseSolved(Object.keys(lineChartData).length);

              console.log(lineChartData);
              let totalScore = 0;
              let jdgScore = 0;
              let anlScore = 0;
              let synScore = 0;
              let strScore = 0;
              lineChartData.forEach((ele) => {
                  if (typeof ele.score["anl"] !== "undefined")
                      anlScore += parseFloat(ele.score["anl"]);
                  if (typeof ele.score["jdg"] !== "undefined")
                      jdgScore += parseFloat(ele.score["jdg"]);
                  if (typeof ele.score["syn"] !== "undefined")
                      synScore += parseFloat(ele.score["syn"]);
                  if (typeof ele.score["str"] !== "undefined")
                      strScore += parseFloat(ele.score["str"]);
              });
              totalScore = anlScore + jdgScore + synScore + strScore;
              setScore(totalScore / Object.keys(lineChartData).length);

              let lowest = "anl",
                  highest = "anl",
                  lowestS = anlScore,
                  highestS = anlScore;
              if (jdgScore < anlScore) {
                  lowest = "jdg";
                  lowestS = jdgScore;
                  setlowestProperty("Judgement");
              }
              if (synScore < lowestS) {
                  lowest = "syn";
                  lowestS = synScore;
                  setlowestProperty("Synthesis");
              }
              if (strScore < lowestS) {
                  lowest = "str";
                  lowestS = strScore;
                  setlowestProperty("Structure");
              }
              if (jdgScore > anlScore) {
                  highest = "jdg";
                  highestS = jdgScore;
                  sethighestProperty("Judgement");
              }
              if (synScore > highestS) {
                  highest = "syn";
                  highestS = synScore;
                  sethighestProperty("Synthesis");
              }
              if (strScore > highestS) {
                  highest = "str";
                  highestS = strScore;
                  sethighestProperty("Structure");
              }
              const averageLowest =
                  lineChartData
                      .filter((x) => x.score[lowest])
                      .reduce((acc, x) => acc + x.score[lowest], 0) /
                  Object.keys(lineChartData).length;
              const averageHighest =
                  lineChartData
                      .filter((x) => x.score[highest])
                      .reduce((acc, x) => acc + x.score[highest], 0) /
                  Object.keys(lineChartData).length;
              const minLowest = lineChartData
                  .filter((x) => x.score[lowest])
                  .reduce((prev, curr) =>
                      prev.score[lowest] < curr.score[lowest] ? curr : prev
                  ).score[lowest];
              const maxHighest = lineChartData
                  .filter((x) => x.score[highest])
                  .reduce((prev, curr) =>
                      prev.score[highest] < curr.score[highest] ? curr : prev
                  ).score[highest];
              sethighestMetrics([averageHighest, maxHighest]);
              console.log(averageLowest);
              setlowestMetrics([averageLowest, minLowest]);
          }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={styles.cardBody}>
      <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            width: "100%",
            alignContent: "center",
          }}
        >
          <div styling={{ width: "80%", backgroundColor: "red" }}>
            <p className={styles.heading} style={{ paddingTop: "6%" }}>
              Consolidated Score
            </p>
          </div>
          <div styling={{ width: "40%", justifyContent: "center" }}>
            <p
              style={{
                textAlign: "right",
                width: "250px",
                marginBottom: "5%",
                paddingBottom: "5%",
              }}
              className={styles.score}
            >
                {score==0?"N/A": score.toFixed(2)}
            </p>
          </div>
        </div>
        <div style={{ width: "100%", height: "35vh", display: "flex" }}>
          <div
            style={{ display: "flex", alignContent: "center", width: "50%" }}
          >
            <div style={{ display: "flex" }} className={styles.firstCol}>
              <div className={styles.mb2}>
                <p style={{ textAlign: "left" }} className={styles.mb1}>
                  Cases Solved &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <span className={styles.solved}>{caseSolved}</span>
                </p>
                <br />
                <p style={{ textAlign: "left" }} className={styles.mb1}>
                  Next Recommended <br /> Case:{" "}
                </p>
                <p style={{ textAlign: "left" }} className={styles.case}>
                  Ice Cream
                  <br /> Manufacturer
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{ display: "flex", flexFlow: "column" }}
              className={styles.icon}
            >
              <img
                src="user (1).png"
                className={styles.userIcon}
                style={{ cursor: "-webkit-grab", cursor: "grab" }}
                onClick={() => {
                  history.push("/profile");
                }}
              ></img>
              <br />
              <p
                style={{ color: "#102364", fontSize: "140%" }}
                className={styles.mb1}
              >
                {name}
              </p>
            </div>
          </div>
        </div>
        <div className="secondColN">
          <Accordion sx={{ borderRadius: "3%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p style={{ color: "#102364" }} className={styles.mb1}>
                Top Skill-{highestProperty}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <SkillsCharts
                skill="Top Skill"
                method={highestProperty}
                data={highestMetrics}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ borderRadius: "3%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p style={{ color: "#102364" }} className={styles.mb1}>
                Needy Skill-{lowestProperty}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <SkillsCharts
                skill="Needy Skill"
                method={lowestProperty}
                data={lowestMetrics}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
