import React, {useState} from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { Button } from '@material-ui/core'
import axios from '../../axios'
import '../../styles/CaseCard.css'
import { useHistory } from 'react-router'
import {Modal} from 'react-responsive-modal'

export default function CaseCard(props) {

  const [modal, setModal] = useState(false)
  const onOpenModal = () => {setModal(true)};
  const onCloseModal = () => setModal(false);
  const [resp,setResp] = useState({})

  let history = useHistory()
  const data = { caseNo: props.id }
  function clearcurrentPending(){
    const getData = {
      user_id : "",
      mode: 3,
      message1 : "",
      message2 : "z",
    };  
    axios.post('/userconvo', JSON.stringify(getData), {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      history.push('/cases')
  }).catch((err)=> console.log(err));

  }
  const startSession = (e) => {
    // const url = process.env.REACT_APP_BASE_SERVER_URL+'/check-cases'

    // const data = {
    //   caseNo: '1',
    // }

     axios
      .post('/check-cases', data)
       .then((res) => {
        setResp(res)
        if (res.data.hasPendingCases){
          onOpenModal()
        }
        else{
         history.push({
          pathname: '/chatbox',
          state: {id: props.id,  heading: props.heading, prob:props.problem,email:res.data.email, resume:false}
        })}
     })
     .catch((err) => {
       console.log(err.response)
       })
    e.preventDefault()
  }

  return (
    <Card
      sx={{ minWidth: 275 }}
      className='card shadow'
      style={{ height: '100%' }}
    >
      <Modal open={modal} onClose={onCloseModal} center>
        <p className="modal-text">
          Do you want to resume the case
        </p>
        <div className="button-div">
          <Button className="modal-button" onClick={()=>{
            onCloseModal()
            history.push({
              pathname: '/chatbox',
              state: {id: resp.data.caseNo, startT:resp.data.startT,email:resp.data.email, heading: resp.data.title, prob:resp.data.problem, resume:true}
            })
          }}>
            Ok
          </Button>
          <Button className="cancel-modal-button" onClick={() =>{
            onCloseModal()
            clearcurrentPending()
          }}>
            Cancel
          </Button>
        </div>
      </Modal>
      <CardContent className='cardContent' sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              align='left'
              style={{
                height: '100px',
                fontSize: '33px',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
              className='heading'
            >
              {props.heading}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14 }}
              variant='h5'
              color='text.secondary'
              align='left'
              className='card-body'
            >
              {props.difficulty}
              <br />
              {props.about}
              <br />
              <Button
                className='card-button'
                style={{
                  backgroundColor: '#006FFF',
                  color: 'whitesmoke',
                  marginTop: '10px',
                  textTransform: 'none',
                  padding: '10px 24px !important',
                }}
                onClick={startSession}
              >
                Start Now
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img src={props.icon} alt='' srcset='' width='100' height='100' />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
