import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core'
import '../../styles/Cases.css'

export default function Cases() {
  return (
    <div>
      <Container maxWidth="lg">
      <Grid container spacing={4} className="hero">
        <Grid item xs={12} sm={6} align="center" className="heroTextSection">
          <Typography color="textPrimary" gutterBottom className="heroText">
            Case
          </Typography>
          <Typography color="textPrimary" gutterBottom className="heroText">
            Selector
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}align="center">
          <Container>
          <img src="goal.png" alt="" srcset="" style={{width: 300, height: 300}} />
          </Container>
        </Grid>
      </Grid>
      </Container>
    </div>
  )
}
