import React, {useState} from "react";
import axios from "../../axios"
import { useHistory } from 'react-router-dom'

import ChatInput from "./helper_section/ChatInput";
import ChatInput2 from "./helper_section/ChatInput2";
import ChatInput3 from "./helper_section/ChatInput3";
import MultiSelect from "./helper_section/MultiSelect";
import Instruction from "./helper_section/Instruction";
import Dnd from "./helper_section/Dnd"
import "../../styles/btn.css"
import { Button } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import '../../styles/modal.css'

const HelperSection = ({messages, addToMessageBox, setNextQues, nextQues, seeQues, setSeeQues, setCaseState, setProgress, setIntervalId, stop}) => {

  const [modal, setModal] = useState(false);
  const onOpenModal = () => {setModal(true)};
  const onCloseModal = () => setModal(false);

  let history = useHistory();
  const url = '/userconvo'

  

  const ele = () => {
     
    if(messages.messages[0].qid === "-1")
    return <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:"flex", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' onClick={onOpenModal}>
        End Chat
      </button>
    </div>

    if(nextQues.length==0&&!messages.messages[0].qtype)
      return <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:"flex", alignItems:"center", justifyItems:
      "center"}}>
      </div> 
     if(!messages.messages[0].qtype&&!messages.messages[0].isMyMessage)
      return <ChatInput3 addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} setSeeQues={setSeeQues}/>
    if (
      messages.messages.length === 0 ||
      !("qtype" in messages.messages[0]) ||
      messages.messages[0].qtype[0] === "n"
    ){
			if(messages.messages[0].qtype[3] === "0")
        return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress}/>
      else{
        let noOfInputs = parseInt(messages.messages[0].qtype[3])
        return <ChatInput2 addToMessageBox={addToMessageBox} noOfInputs={noOfInputs} messages={messages} setNextQues={setNextQues} setProgress={setProgress}/>
      }
		}
    else if(messages.messages[0].qtype === "txt0"){
      return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress}/>
    }
    else if(messages.messages[0].qtype === "rng0"){
      return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress}/>
    }
    else if (messages.messages[0].qtype[0] === "m"){
      let instruction
      if(messages.messages[0].qtype[3] === "0")
        instruction = "Choose any number of the options"
      else if(messages.messages[0].qtype.includes('max'))
        instruction = `Choose upto ${messages.messages[0].qtype[3]} options`
      else if(messages.messages[0].qtype[3] === "1")
        instruction = "Choose one of the options"
      else
        instruction = `Choose exact ${messages.messages[0].qtype[3]} options`

      return (
				<>
        <Instruction instruction={instruction} 
          seeQues = {seeQues}
          setSeeQues= {setSeeQues}
          ques = {messages.messages[0].q}/>
        <MultiSelect
          messages={messages}
          addToMessageBox={addToMessageBox}
          setNextQues={setNextQues}
          seeQues={seeQues}
          setSeeQues={setSeeQues}
          setProgress={setProgress}
        />
				</>
      );
    }
    else if (messages.messages[0].qtype[0] === "s"){
      var instruction = `Arrange first ${messages.messages[0].qtype[3]} in order`
      return (
				<>
        <Instruction instruction={instruction} 
          seeQues = {seeQues}
          setSeeQues= {setSeeQues}
          ques = {messages.messages[0].q}/>
        {/* <DragAndDrop
          messages={messages}
          addToMessageBox={addToMessageBox}
          setNextQues={setNextQues}
          seeQues={seeQues}
          setSeeQues={setSeeQues}
        /> */}
        <Dnd
           messages={messages}
           addToMessageBox={addToMessageBox}
           setNextQues={setNextQues}
           seeQues={seeQues}
           setSeeQues={setSeeQues}
           setProgress={setProgress}
        />
				</>
      );
    }
  };

  let renderElement = ele();
  return (
    <div style={{background: "#0E6495", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", position: "absolute", bottom: "0", width: "100%", zIndex:"2"}}>
      <Modal open={modal} onClose={onCloseModal} center>
                <p className="modal-text">
                  You're done! Click OK to leave the chat window
                </p>
                <div className="button-div">
                  <Button className="modal-button" onClick={()=>{
                    stop()
                    onCloseModal()
                    const postData = {
                      mode: 3,
                      message1: '',
                      message2: ''
                    }
                
                    axios
                      .post(url, postData)
                      .then((res) => {
                        // redirect to dashboard page
                        setCaseState(false)
                        history.push('/signin')
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}>
                    Ok
                  </Button>
                </div>
              </Modal>
      {renderElement}
    </div>
  );
};

export default HelperSection;
