import React from 'react'
import Navbar from '../components/Navbar'
// import Report from '../components/report/ReportHeader'
import '../App.css'
import ReportHeader from '../components/report/ReportHeader'
import useFetchUser from "../custom-hooks/useFetchUser";

const ReportPage = (props) => {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
            <ReportHeader/>
        </div>
    )
}

export default ReportPage
