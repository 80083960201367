import React from 'react'
import styles from '../../styles/Interview.module.css'
import { Button } from '@material-ui/core'
import dateFormat from 'dateformat'

export default function Interview(props) {
  const score = 0.75
  console.log(props)
  const { _id, createdDate, title } = props.detail
  //const linkref = 'http://localhost:5000/api/pdf-report/' + _id
  const linkref = 'https://www.aspiringconsultants.com/api/pdf-report/' + _id
  const date = dateFormat(createdDate, 'mm/dd/yyyy, h:MM:ss TT')
  return (
    <div>
      <div className={styles.row}>
        <p className={styles.case}>{title}</p>
        <p className={styles.date}>{date}</p>
        <p className={styles.score}>{score}</p>
        <div className={styles.btnGroup}>
          <Button variant='contained' size='small' className={styles.idealBtn}>
            Ideal
          </Button>
          <Button
            href={linkref}
            target='_blank'
            variant='contained'
            size='small'
            className={styles.reportBtn}
          >
            Report
          </Button>
        </div>
      </div>
    </div>
  )
}
