import React from 'react'
import Navbar2 from '../components/Navbar2'
import SetNewPassword from '../components/SetNewPassword'

const SetNewPasswordPage = () => {
    return (
        <div>
            <Navbar2 />
            <SetNewPassword />
        </div>
    )
}

export default SetNewPasswordPage
