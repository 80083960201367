// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
    width: 5rem;
    height: 5rem;
    border: 1px solid #0D95BB;
    border-radius: 50%;
}

.value {
    color: #0D95BB;
    font-size: 1.5rem;
}

.innercircle>p {
    margin: 0;
    padding: 0;
}

.perform {
    color: #102364
}

.innercircle {
    width: 4rem;
    height: 4rem;
    border: 1px solid #0D95BB;
    border-radius: 50%;
    margin: auto;
    margin-top: 8%;
    background-color: white;
    display: grid;
    place-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/Circle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,uBAAuB;IACvB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".circle {\n    width: 5rem;\n    height: 5rem;\n    border: 1px solid #0D95BB;\n    border-radius: 50%;\n}\n\n.value {\n    color: #0D95BB;\n    font-size: 1.5rem;\n}\n\n.innercircle>p {\n    margin: 0;\n    padding: 0;\n}\n\n.perform {\n    color: #102364\n}\n\n.innercircle {\n    width: 4rem;\n    height: 4rem;\n    border: 1px solid #0D95BB;\n    border-radius: 50%;\n    margin: auto;\n    margin-top: 8%;\n    background-color: white;\n    display: grid;\n    place-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
