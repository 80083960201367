import React from 'react'
import Dashboard from '../components/dashboard/Dashboard'
import Navbar from '../components/Navbar'
import '../App.css'
import useFetchUser from "../custom-hooks/useFetchUser";

const DashboardPage = () => {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
            <Dashboard/>
        </div>
    )
}

export default DashboardPage
