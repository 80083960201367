import React from 'react'
import Products from '../components/all-products/Products'
import Navbar from '../components/Navbar'
import useFetchUser from "../custom-hooks/useFetchUser";

export default function ProductPage() {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{height: "70vh", minWidth: "90vw"}}>

                    <Products/>
                </div>
            </div>
        </div>
    )
}
