// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  padding-top: 10vh;
}

.heroTextSection {
  margin: auto;
  display: table-cell;
  vertical-align: middle;
}


.heroText {
  color: #ffffff !important;
  font-weight: 500 !important;
  padding-left: 0px !important;
  letter-spacing: 10px !important;
}

@media (max-width: 480px) {
  .heroText {
  font-size: 50px !important;
  }
}

@media (max-width: 600px) {
  .heroText {
    font-size: 60px !important;
    }
}

@media (max-width: 768px) {
  .heroText {
    font-size: 70px !important;
    }
}

@media (min-width: 768px) {
  .heroText {
    font-size: 80px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Cases.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;AACxB;;;AAGA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE;EACA,0BAA0B;EAC1B;AACF;;AAEA;EACE;IACE,0BAA0B;IAC1B;AACJ;;AAEA;EACE;IACE,0BAA0B;IAC1B;AACJ;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".hero {\n  padding-top: 10vh;\n}\n\n.heroTextSection {\n  margin: auto;\n  display: table-cell;\n  vertical-align: middle;\n}\n\n\n.heroText {\n  color: #ffffff !important;\n  font-weight: 500 !important;\n  padding-left: 0px !important;\n  letter-spacing: 10px !important;\n}\n\n@media (max-width: 480px) {\n  .heroText {\n  font-size: 50px !important;\n  }\n}\n\n@media (max-width: 600px) {\n  .heroText {\n    font-size: 60px !important;\n    }\n}\n\n@media (max-width: 768px) {\n  .heroText {\n    font-size: 70px !important;\n    }\n}\n\n@media (min-width: 768px) {\n  .heroText {\n    font-size: 80px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
