import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { Button } from '@material-ui/core'
import axios from '../../axios'
import '../../styles/CaseCard.css'
import { useHistory } from 'react-router'
import { height } from '@mui/system'

export default function CaseCard(props) {
  let history = useHistory()
  // const [btnName, setBtnName] = useState('Add To Cart')

  const data = { caseNo: props.id }

  const startSession = (e) => {
    // const url = process.env.REACT_APP_BASE_SERVER_URL+'/check-cases'

    // const data = {
    //   caseNo: '1',
    // }

    // axios
    //   .post('/check-cases', data)
    //   .then((res) => {
    //     console.log(res)
    history.push({
      pathname: '/chatbox',
      state: { id: props.id, heading: props.heading },
    })
    // })
    // .catch((err) => {
    //   console.log(err.response)
    // const state = { data: data }
    // const title = ''
    // const url = window.location.origin+"/chatbox";
    // {console.log(err,state,title,url)}

    // history.push(state, title, url)
    // })
    e.preventDefault()
  }

  return (
    <Card
      sx={{ minWidth: 275 }}
      className='card shadow'
      style={{ height: '100%' }}
    >
      <CardContent className='cardContent' sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              align='left'
              style={{
                height: '100px',
                fontSize: '33px',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
              className='heading'
            >
              {props.heading}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14 }}
              variant='h5'
              color='text.secondary'
              align='left'
              className='card-body'
            >
              {props.difficulty}
              <br />
              {props.about}
              <br />
              <Button
                className='card-button'
                style={{
                  backgroundColor: '#006FFF',
                  color: 'whitesmoke',
                  marginTop: '10px',
                  textTransform: 'none',
                  padding: '10px 24px !important',
                }}
                onClick={(e) => props.clickHandler(e, props.id)}
              >
                Add To Cart
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                borderRadius: '50%',
                display: 'grid',
                placeItems: 'center',
                width: '5rem',
                height: '5rem',
                backgroundColor: '#54c054',
              }}
            >
              <h2> {props.icon} </h2>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
