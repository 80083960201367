import React from 'react'
import Navbar2 from '../components/Navbar2'
import ResetPassword from '../components/ResetPassword'

const ResetPasswordPage = () => {
  return (
    <div>
      <Navbar2 />
      <ResetPassword />
    </div>
  )
}

export default ResetPasswordPage
