import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core'
import '../styles/Cases.css'
import Interviews from './interviews/Interviews'
import { useWindowDimensions } from './../utils/windowUtils'

export default function History() {
  const { width } = useWindowDimensions()

  const textStyle = {
    marginTop: '2rem',
    fontSize: width > 992 ? '6rem' : '4.5rem',
    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: '3rem',
  }
  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container spacing={4} className='hero'>
          <Grid item xs={12} sm={6} className='' align='left'>
            <Typography
              color='textPrimary'
              // className='heroText'
              style={textStyle}
            >
              History
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} align='center'>
            <Container>
              <img
                src='goal.png'
                alt=''
                srcset=''
                style={{ width: 300, height: 300 }}
              />
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Interviews />
    </div>
  )
}
