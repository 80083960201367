// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiSelect_selected__B2TlO {
  border: 5px solid !important;
  border-color: white !important;
  background-color: #377ca3 !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/MultiSelect.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,8BAA8B;EAC9B,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".selected {\n  border: 5px solid !important;\n  border-color: white !important;\n  background-color: #377ca3 !important;\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": `MultiSelect_selected__B2TlO`
};
export default ___CSS_LOADER_EXPORT___;
