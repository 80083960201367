import React from 'react'
import Navbar from '../components/Navbar'
import Profile from '../components/profile/Profile'
import '../App.css'
import useFetchUser from "../custom-hooks/useFetchUser";

const ProfilePage = (props) => {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
            <Profile/>
        </div>
    )
}

export default ProfilePage
