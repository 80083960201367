// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetails_paper__4X2Lx {
    width: 100vh;
    margin: 1rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.UserDetails_icon__r5yIY {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UserDetails_userIcon__0qpmK {
    width: 100px;
    height: 100px;

}

.UserDetails_label__sWbjJ {
    margin-bottom: 0.5rem;

}

.UserDetails_info__qq7xb {
    margin-bottom: 0.5rem;
    color: #3f1665;
    text-decoration: underline;
    text-decoration-color: #aea3b7;
}

.UserDetails_button__YjYPq {
    color: #fff !important;
    font-size: 0.7rem !important;
    font-weight: bold;
    text-transform: none !important;
    border-radius: 5px !important;
    /* padding: 4px 1rem !important; */

    /* padding: 4px 20px !important; */
}

@media (max-width: 768px) {
    .UserDetails_paper__4X2Lx {
        padding: 0.75rem !important;
        width: 80vw;

        /* over */
    }
}



@media(max-width: 580px) {

    .UserDetails_label__sWbjJ,
    .UserDetails_info__qq7xb {
        font-size: 1rem;
    }

    .UserDetails_userIcon__0qpmK {
        width: 75px;
        height: 75px;

    }

}

@media(max-width: 450px) {

    .UserDetails_label__sWbjJ,
    .UserDetails_info__qq7xb {
        font-size: 0.75rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/UserDetails.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,8BAA8B;IAC9B,2CAA2C;;AAE/C;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;;AAEjB;;AAEA;IACI,qBAAqB;;AAEzB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,0BAA0B;IAC1B,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,iBAAiB;IACjB,+BAA+B;IAC/B,6BAA6B;IAC7B,kCAAkC;;IAElC,kCAAkC;AACtC;;AAEA;IACI;QACI,2BAA2B;QAC3B,WAAW;;QAEX,SAAS;IACb;AACJ;;;;AAIA;;IAEI;;QAEI,eAAe;IACnB;;IAEA;QACI,WAAW;QACX,YAAY;;IAEhB;;AAEJ;;AAEA;;IAEI;;QAEI,kBAAkB;IACtB;AACJ","sourcesContent":[".paper {\n    width: 100vh;\n    margin: 1rem auto;\n    padding: 2rem;\n    background: #fff;\n    border-radius: 1rem !important;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n\n}\n\n.icon {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.userIcon {\n    width: 100px;\n    height: 100px;\n\n}\n\n.label {\n    margin-bottom: 0.5rem;\n\n}\n\n.info {\n    margin-bottom: 0.5rem;\n    color: #3f1665;\n    text-decoration: underline;\n    text-decoration-color: #aea3b7;\n}\n\n.button {\n    color: #fff !important;\n    font-size: 0.7rem !important;\n    font-weight: bold;\n    text-transform: none !important;\n    border-radius: 5px !important;\n    /* padding: 4px 1rem !important; */\n\n    /* padding: 4px 20px !important; */\n}\n\n@media (max-width: 768px) {\n    .paper {\n        padding: 0.75rem !important;\n        width: 80vw;\n\n        /* over */\n    }\n}\n\n\n\n@media(max-width: 580px) {\n\n    .label,\n    .info {\n        font-size: 1rem;\n    }\n\n    .userIcon {\n        width: 75px;\n        height: 75px;\n\n    }\n\n}\n\n@media(max-width: 450px) {\n\n    .label,\n    .info {\n        font-size: 0.75rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `UserDetails_paper__4X2Lx`,
	"icon": `UserDetails_icon__r5yIY`,
	"userIcon": `UserDetails_userIcon__0qpmK`,
	"label": `UserDetails_label__sWbjJ`,
	"info": `UserDetails_info__qq7xb`,
	"button": `UserDetails_button__YjYPq`
};
export default ___CSS_LOADER_EXPORT___;
