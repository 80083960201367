import React from 'react'
import Navbar from '../components/Navbar'
import CaseSelector from '../components/cases/CaseSelector'
import Cases from '../components/cases/Cases'
import '../App.css'
import useFetchUser from "../custom-hooks/useFetchUser";

const CasesPage = ({ cards }) => {
    const data = useFetchUser();
  return (
    <div className='App'>
      <Navbar userData={data}/>
      <Cases />
      <CaseSelector cards={cards} />
    </div>
  )
}

export default CasesPage
