// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* p {
    padding: 0;
    margin: 0;
} */

.InterviewDetails_row__75ywU>p {
    padding: 0;
    margin: 0;
}

.InterviewDetails_row__75ywU {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ecf0f3;
    color: #575b5e;

    font-size: 0.9rem;
    margin-top: 0.25rem;
    padding: 0.25rem 1.75rem;
    border-radius: .25rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/InterviewDetails.module.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,cAAc;;IAEd,iBAAiB;IACjB,mBAAmB;IACnB,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":["/* p {\n    padding: 0;\n    margin: 0;\n} */\n\n.row>p {\n    padding: 0;\n    margin: 0;\n}\n\n.row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #ecf0f3;\n    color: #575b5e;\n\n    font-size: 0.9rem;\n    margin-top: 0.25rem;\n    padding: 0.25rem 1.75rem;\n    border-radius: .25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `InterviewDetails_row__75ywU`
};
export default ___CSS_LOADER_EXPORT___;
