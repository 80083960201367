// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Interviews2_paper__cj46F {
    width: 65vw;
    margin-top: 3rem !important;
    margin: 1rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem !important;
    padding-left: 3rem;
    padding-top: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* p {
    padding: 0;
    margin: 0;
} */

.Interviews2_tableHead__5KmiJ>p {
    padding: 0;
    margin: 0;
}

.Interviews2_tableHead__5KmiJ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3F1665;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 700;
    /* font-weight: bold; */
    margin-top: 1rem;
    padding: 0.25rem 1.5rem;
    border-radius: .25rem;
}

.Interviews2_feedback__m6fen {
    width: 4.5rem;
}

@media (max-width: 900px) {
    .Interviews2_paper__cj46F {
        width: 80vw !important;
    }
}

@media (max-width: 680px) {
    .Interviews2_paper__cj46F {
        width: 600px !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        overflow-x: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Interviews2.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;IAC3B,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;IACjB,2CAA2C;AAC/C;;AAEA;;;GAGG;;AAEH;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,uBAAuB;QACvB,6BAA6B;QAC7B,8BAA8B;QAC9B,gBAAgB;IACpB;AACJ","sourcesContent":[".paper {\n    width: 65vw;\n    margin-top: 3rem !important;\n    margin: 1rem auto;\n    padding: 2rem;\n    background: #fff;\n    border-radius: 1rem !important;\n    padding-left: 3rem;\n    padding-top: 1rem;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n/* p {\n    padding: 0;\n    margin: 0;\n} */\n\n.tableHead>p {\n    padding: 0;\n    margin: 0;\n}\n\n.tableHead {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #3F1665;\n    color: #fff;\n    font-size: 0.9rem;\n    font-weight: 700;\n    /* font-weight: bold; */\n    margin-top: 1rem;\n    padding: 0.25rem 1.5rem;\n    border-radius: .25rem;\n}\n\n.feedback {\n    width: 4.5rem;\n}\n\n@media (max-width: 900px) {\n    .paper {\n        width: 80vw !important;\n    }\n}\n\n@media (max-width: 680px) {\n    .paper {\n        width: 600px !important;\n        padding-left: 1rem !important;\n        padding-right: 1rem !important;\n        overflow-x: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `Interviews2_paper__cj46F`,
	"tableHead": `Interviews2_tableHead__5KmiJ`,
	"feedback": `Interviews2_feedback__m6fen`
};
export default ___CSS_LOADER_EXPORT___;
