// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.Navbar_navLink__dqjzQ a {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.25rem;
    padding: 0;
    /* text-transform: uppercase; */
    color: #fff;
    margin-right: 1.2rem;
    opacity: 1;
    padding: 0 1.25rem;
}

.Navbar_navLink__dqjzQ a:hover {
    color: rgba(255, 255, 255, 0.801);
    text-decoration: none;
    opacity: 0.9;
    cursor: pointer;
}

.Navbar_active__cy5tg {
    color: #0CBA9B !important;
}

/* .menuItemsImg {
    height: '2rem';
    display: 'block';
    padding-top: '0.5rem';
    margin-right: '1.5rem';
    padding-left: '1rem';
}

.menuItemsSubtitle {
    color: '#9e8f8e';
    margin: '0';
    font-size: '0.75rem';
} */

@media(max-width: 992px) {
    .Navbar_navLink__dqjzQ a {
        display: block;
        margin: 1rem 1.2rem;
        padding: 0.5rem 0;
        border-bottom: 1px solid #c4c4c444;
    }

    .Navbar_brandLogo__B7HWU {
        margin: 1.25rem auto;
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.module.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;IACI,uCAAuC;IACvC,kBAAkB;IAClB,UAAU;IACV,+BAA+B;IAC/B,WAAW;IACX,oBAAoB;IACpB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;IACjC,qBAAqB;IACrB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;;;;;;;;;;GAYG;;AAEH;IACI;QACI,cAAc;QACd,mBAAmB;QACnB,iBAAiB;QACjB,kCAAkC;IACtC;;IAEA;QACI,oBAAoB;QACpB,cAAc;IAClB;AACJ","sourcesContent":["/* * {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n} */\n\n.navLink a {\n    font-family: 'Josefin Sans', sans-serif;\n    font-size: 1.25rem;\n    padding: 0;\n    /* text-transform: uppercase; */\n    color: #fff;\n    margin-right: 1.2rem;\n    opacity: 1;\n    padding: 0 1.25rem;\n}\n\n.navLink a:hover {\n    color: rgba(255, 255, 255, 0.801);\n    text-decoration: none;\n    opacity: 0.9;\n    cursor: pointer;\n}\n\n.active {\n    color: #0CBA9B !important;\n}\n\n/* .menuItemsImg {\n    height: '2rem';\n    display: 'block';\n    padding-top: '0.5rem';\n    margin-right: '1.5rem';\n    padding-left: '1rem';\n}\n\n.menuItemsSubtitle {\n    color: '#9e8f8e';\n    margin: '0';\n    font-size: '0.75rem';\n} */\n\n@media(max-width: 992px) {\n    .navLink a {\n        display: block;\n        margin: 1rem 1.2rem;\n        padding: 0.5rem 0;\n        border-bottom: 1px solid #c4c4c444;\n    }\n\n    .brandLogo {\n        margin: 1.25rem auto;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navLink": `Navbar_navLink__dqjzQ`,
	"active": `Navbar_active__cy5tg`,
	"brandLogo": `Navbar_brandLogo__B7HWU`
};
export default ___CSS_LOADER_EXPORT___;
