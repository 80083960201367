import React from 'react'
import Chats from '../components/all-chats/Chats'
import Navbar from '../components/Navbar'
import useFetchUser from "../custom-hooks/useFetchUser";

export default function ChatPage() {
    const data = useFetchUser();
    return (
        <div className='App'>
            <Navbar userData={data}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{height: "70vh", minWidth: "90vw"}}>
                    <Chats/>
                </div>
            </div>
        </div>
    )
}
