// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid_second {
  width: auto;
  margin: auto;
  margin-top: 5%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10%;
}

.heading2 {
  margin-bottom: 2%;
  color: #102364;
}

.grid_second {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/SkillsCharts.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,qCAAqC;EACrC,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".grid_second {\n  width: auto;\n  margin: auto;\n  margin-top: 5%;\n  display: grid;\n  place-items: center;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 10%;\n}\n\n.heading2 {\n  margin-bottom: 2%;\n  color: #102364;\n}\n\n.grid_second {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
