import React, { useState } from 'react'
import styles from '../../styles/MainReport.module.css'
import { Paper, Grid } from '@material-ui/core'
import { Box } from '@mui/system'
import LineChart from './charts/Linechart'

export default function MainReport() {
  const [structBtn, setStructBtn] = useState(true)
  const [scopeBtn, setScopeBtn] = useState(false)
  const [analyisBtn, setAnalyisBtn] = useState(false)
  const [timeBtn, setTimeBtn] = useState(false)
  const [summary, setSummary] = useState(true)
  const [type, setType] = useState(false)
  const [difficulty, setDifficulty] = useState(false)
  const [currentButton, setCurrentButton] = useState("ANL")
  const handleClick = (e) => {
    e.preventDefault()
    let btnName = e.target.innerText
    btnName = btnName.toString().toLowerCase()
    if (btnName.includes('structuring')) {
      setCurrentButton("STR")
      setStructBtn(true)
      setScopeBtn(false)
      setAnalyisBtn(false)
      setTimeBtn(false)
    } else if (btnName.includes('scoping')) {
      setCurrentButton("SYN")
      setStructBtn(false)
      setScopeBtn(true)
      setAnalyisBtn(false)
      setTimeBtn(false)
    } else if (btnName.includes('analysis')) {
      setCurrentButton("ANL")
      setStructBtn(false)
      setScopeBtn(false)
      setAnalyisBtn(true)
      setTimeBtn(false)
    } else if (btnName.includes('time')) {
      setCurrentButton("JDG")
      setStructBtn(false)
      setScopeBtn(false)
      setAnalyisBtn(false)
      setTimeBtn(true)
    } else  if (btnName.includes('summary')) {
      setSummary(true)
      setType(false)
      setDifficulty(false)
    }
    else if (btnName.includes('type')) {
      setSummary(false)
      setType(true)
      setDifficulty(false)
    }
    else if (btnName.includes('difficulty')) {
      setSummary(false)
      setType(false)
      setDifficulty(true)
    }
  }

  return (
    <div>
      <Box mt={10}></Box>
      <Paper className={styles.paper}>
        <div className={styles.Navigations}>
          <div
            className={[styles.topNavigation, summary&& styles.active].join(
              ' '
            )}
            onClick={handleClick}
          >
            Summary Report
          </div>
          <div
            className={[styles.topNavigation, type && styles.active].join(
              ' '
            )}
            onClick={handleClick}
          >
            Type of Case
          </div>
          <div
            className={[styles.topNavigation, difficulty && styles.active].join(
              ' '
            )}
            onClick={handleClick}
          >
            By Difficulty Level
          </div>
        </div>
        <Box mt={2}> </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} align='center'>
            <div className={styles.btns}>
              <Box mt={4}>
                <button
                  variant='contained'
                  className={[styles.btn, structBtn && styles.active].join(' ')}
                  onClick={(e) => handleClick(e)}
                >
                  Structuring
                </button>
              </Box>
              <Box mt={4}>
                <button
                  variant='contained'
                  className={[styles.btn, scopeBtn && styles.active].join(' ')}
                  onClick={(e) => handleClick(e)}
                >
                  Scoping (Prelim)
                </button>
              </Box>
              <Box mt={4}>
                <button
                  variant='contained'
                  className={[styles.btn, analyisBtn && styles.active].join(
                    ' '
                  )}
                  onClick={(e) => handleClick(e)}
                >
                  Analysis and Insight
                </button>
              </Box>
              <Box mt={4}>
                <button
                  variant='contained'
                  className={[styles.btn, timeBtn && styles.active].join(' ')}
                  onClick={(e) => handleClick(e)}
                >
                  Time Management{' '}
                </button>
              </Box>
            </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper className={styles.paper2} elevation={10}>
              <LineChart data= {currentButton} />
            </Paper>
          </Grid>
          <Box mt={4}>
            <p className={styles.bottomText}>
              Your score is moving in a very high variance bracket. This means
              that either you are being erratic in performance or you are strong
              at certain types of cases. Do check the Type of Case tab for
              further insight. Moving forward, developing consistency should be
              a major objective for you.Your overall band average is decent.
              This shows that overall you have good skills. If your variance is
              high, would should focus more on building consistency to become a
              start problem solverYour slope of performance is facing downwards.
              We should analyse the reasons for the same. This could either be
              affected by type of problem being solved (i.e. lesser
              understanding of say- market entry cases) or in rigidity in
              framework being used as it may not be suitable for each problem.
            </p>
          </Box>
        </Grid>
      </Paper>
    </div>
  )
}