import { Paper, Link } from '@material-ui/core'
import styles from '../styles/SignIn.module.css'
import {
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
} from './MaterialComponents'
import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import axios from '../axios'
import { useHistory } from 'react-router-dom'
import CasesPage from '../views/CasesPage'
import { validateSignInInput } from '../utils/validation/validateUtils'
import ProfilePage from '../views/ProfilePage'
import Loader from '../utils/Loader'
import '../styles/btn.css'
import Error from '../utils/Error'

function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const token = localStorage.getItem('accessToken')
  // if (token) setIsAuthenticated(true)
  let history = useHistory()

  const handleSignIn = (e) => {
    e.preventDefault()

    setIsLoading(true)
    setErrors({})
    const data = {
      email,
      password
    }

    const errorResult = validateSignInInput(data)

    if (!errorResult.isValid) {
      setIsLoading(false)

      setErrors(errorResult.errors)
      console.log(errors)
      return
    }

    axios
      .post('/auth/login', { ...data })
      .then((response) => {
        setIsLoading(false)
        localStorage.setItem('accessToken', response.data.accessToken)
        localStorage.setItem('refreshToken', response.data.accessToken)
        setIsAuthenticated(true)
        history.push('/dashboard')
      })
      .catch((error) => {
          console.log(error)
          if(error?.response?.data?.message=="Please set you password"){
              history.push("/set-password")
          }
          // alert(error?.response?.data?.message)
        setIsLoading(false)
        console.log(error.response)
        setIsAuthenticated(false)
      })
  }

  const labelStyle = {
    color: '#555',
    fontWeight: '600',
    marginBottom: '0.5rem',
  }

  return isAuthenticated ? (
    <CasesPage />
  ) : (
    <form className={styles.forgotMain}>
      <img
        className={styles.sideImage}
        src='./17876784.jpg'
        height='450px'
        alt=''
      ></img>
      <Paper elevation={10} className={styles.paper}>
        <div align='center'>
          <h1
            style={{
              fontSize: '2.5rem',
            }}
          >
            Sign In
          </h1>
        </div>
        <div style={{ marginLeft: '2rem' }}>
          <p style={labelStyle}>Email Address</p>
          <PrimaryInput
            error
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src='./user.png' style={{ height: '2rem' }} alt=''></img>
                </InputAdornment>
              ),
            }}
            placeholder='Type your email address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            helperText={errors.email}
          ></PrimaryInput>
          <p style={labelStyle}>Password</p>
          <PrimaryInput
            error
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img
                    src='./padlock.png'
                    style={{ height: '2rem', color: '#555' }}
                    alt=''
                  ></img>
                </InputAdornment>
              ),
            }}
            placeholder='Type your password'
            type='password'
            helperText={errors.password}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></PrimaryInput>
          <Link href='/forgot-password'>
            <p style={{ color: '#555', fontWeight: '650' }} align='right'>
              Forgot Password?
            </p>
          </Link>
          <div
            style={{
              marginTop: '2rem',
              paddingBottom: '1.75rem',
            }}
            align='center'
          >
            <PrimaryButton
              style={{ width: '80%' }}
              type='submit'
              onClick={handleSignIn}
            >
              LOGIN
            </PrimaryButton>
          </div>
          <div
            style={{
              paddingBottom: '1rem',
            }}
            align='center'
          >
            {/*<SecondaryButton style={{ width: '80%' }}>*/}
            {/*  CHECK DEMO*/}
            {/*</SecondaryButton>*/}

            {isLoading && <Loader />}
            <div
              style={{ marginTop: '3rem', color: '#555', fontWeight: '600' }}
              align='center'
            >
              <p>Not a member yet?</p>
              <Link href='/signUp'>
                <h3
                  style={{
                    marginTop: '0.25rem',
                    fontWeight: 'bold',
                    color: '#555',
                  }}
                >
                  SIGN UP
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </Paper>
    </form>
  )
}

export default SignIn